import React, { createContext, useState, useContext } from 'react';

// Create the context
const GlobalContext = createContext();

// Provider component that wraps your application
export const GlobalProvider = ({ children }) => {
    const [casdoorConfig, setCasdoorConfig] = useState(null);
    const [user, setUser] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarExpanded(!isSidebarExpanded);
    };
    
    // Provide all state variables and setters through context
    const value = {
        casdoorConfig,
        setCasdoorConfig,
        user,
        setUser,
        authToken,
        setAuthToken,
        isSidebarExpanded,
        setIsSidebarExpanded,
        toggleSidebar
    };

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};

// Custom hook to use the global context
export const useGlobalContext = () => useContext(GlobalContext);
