import { ThemeProvider } from "next-themes";
import "../css/tailwind.css";
import { GlobalProvider } from '../context/GlobalContext';
import Head from "next/head";

function MyApp({ Component, pageProps }) {
  return (
    <GlobalProvider>
      <Head>
        <title>Generate Residential Purchase Agreement</title>
        <meta
          name="description"
          content="AI-Powered Transaction Coordination Services for Real Estate Agents."
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
         <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
          rel="stylesheet"
        />
      </Head>
      <ThemeProvider attribute="class">
        <Component {...pageProps} />
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default MyApp;
